import posthog from 'posthog-js';
import { initializeHeader } from './header';

document.addEventListener('DOMContentLoaded', () => {
    const envMeta = document.querySelector('meta[name="env"]');
    const env : string = envMeta ? ( envMeta.getAttribute('content') || '') : '';

    if (['production', 'staging'].includes(env)) {
        // Sentry is in globals, loaded from CDN
        // @ts-ignore
        Sentry.init({
            environment: env,
        });
    }

    if (env === 'production') {
        posthog.init('phc_89pzUUTE5WpoH17QVjm8ZdL7qcEV2K0T8lIeQELdhTc',
            {
                api_host: 'https://us.i.posthog.com',
                person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
            }
        )
    }
    initializeHeader();

   
})