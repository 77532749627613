import {SearchManager} from './search';

let searchManager : SearchManager | undefined = undefined

export const initializeHeader = () => {
    let themeVal = localStorage.getItem("ngtheme");
    if (!themeVal) {
        themeVal = "ngblueorangelight";
        localStorage.setItem("ngtheme", themeVal);
    }
    const themeSwitcher = document.querySelector("label.theme-switcher");
    if (themeSwitcher) {
        themeSwitcher.classList.toggle("swap-active", themeVal == "ngblueorangedark");
    }
    document.querySelector('html')?.setAttribute("data-theme", themeVal);

    const headerSearchBox = document.querySelector("#headersearchbox");
    if (!headerSearchBox) {
        return;
    }
    searchManager = new SearchManager(headerSearchBox, (results) => {
        const searchResultsList = document.querySelector("#searchresults ul");
        if (!searchResultsList) {
            return;
        }
        const baseUrl = document.querySelector("#baseurl")?.getAttribute("data-baseurl")?.replace("/placeholder", "");
        searchResultsList.innerHTML = "";
        if (results.tools.length > 0) {
            results.tools.forEach((tool) => {
                searchResultsList.innerHTML += `<li><a href=${baseUrl + tool.refname}>${tool.title}</a></li>`;
            });
        } else {
            searchResultsList.innerHTML += "<li>No results found</li>";
        }
        document.querySelector("#searchresults")?.classList.add("dropdown-open");
    }, (error) => {
        const searchResultsList = document.querySelector("#searchresults ul");
        if (!searchResultsList) {
            return;
        }
        searchResultsList.innerHTML = "";
        searchResultsList.innerHTML += "<li>Error fetching search data</li>";
    });

    document.querySelector("#searchresults")?.addEventListener('mouseleave', (evt) => {
        document.querySelector("#searchresults")?.classList.remove("dropdown-open");
    })
}


// Menu logic
// Close menu when
// 1) another menu is opened
// 2) mouse leaves menu
// 3) Menu item is clicked - this is nav
//

document.querySelectorAll("details").forEach((details) => {
    details.addEventListener('click', (evt) => {
        document.querySelectorAll("details").forEach((d) => {
            if (d !== details) {
                d.removeAttribute('open');
            }
        })
    })
})

// $("details").on('click', function() {
//     $("details").not(this).removeAttr('open');
// })

document.querySelectorAll("details ul").forEach((ul) => {
    ul.addEventListener('mouseleave', (evt) => {
        ul.closest("details")?.removeAttribute('open');
    })
})

// $("details ul").on('mouseleave', function() {
//     this.closest("details")?.removeAttribute('open');
// })

// $("details a").on('click', function() {
//     console.log("select");
//     this.closest("details")?.removeAttribute('open');
// })



// Theme selector - must use JS to do the swap because only some browsers 
// support the CSS-only swap

const themeSwitcher = document.querySelector("label.theme-switcher");
if (themeSwitcher) {
    themeSwitcher.addEventListener('click', (evt) => {
        themeSwitcher.classList.toggle("swap-active");
        const html = document.querySelector('html');
        if (html) {
            const theme = html.getAttribute("data-theme");
            html.setAttribute("data-theme", theme == "ngblueorangelight" ? "ngblueorangedark" : "ngblueorangelight");
            localStorage.setItem("ngtheme", html.getAttribute("data-theme") || "ngblueorangelight");
        }
    })
}

// $("label.theme-switcher").on('click', function(event) {
//     $(this).toggleClass("swap-active");
//     $('html').attr("data-theme", (i, attr) => {
//         const val = attr == "ngblueorangelight" ? "ngblueorangedark" : "ngblueorangelight";
//         localStorage.setItem("ngtheme", val);
//         return val;
//     })
// })


// Mobile menu - open/close
// $("#mobile-menu-button").on('click', function() {
//     $("#mobile-menu").toggleClass("hidden");
//     $("#mobile-menu-block-open").toggleClass("block");
//     $("#mobile-menu-block-open").toggleClass("hidden");
//     $("#mobile-menu-block-closed").toggleClass("hidden");
//     $("#mobile-menu-block-closed").toggleClass("block");
//     $("#nav").toggleClass("open");
// })

// Mobile menu - close when a link is clicked
document.querySelector("#mobile-menu-button")?.addEventListener('click', (evt) => {
    document.querySelector("#mobile-menu")?.classList.toggle("hidden");
    document.querySelector("#mobile-menu-block-open")?.classList.toggle("block");
    document.querySelector("#mobile-menu-block-open")?.classList.toggle("hidden");
    document.querySelector("#mobile-menu-block-closed")?.classList.toggle("hidden");
    document.querySelector("#mobile-menu-block-closed")?.classList.toggle("block");
    document.querySelector("#nav")?.classList.toggle("open");
})